<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar color="#002A53" flat app dense height="40px" dark>
      <v-btn icon :to="{ name: 'rde' }">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>Servicios del Complejo</v-toolbar-title>      
    </v-app-bar> 
    <v-card>
       <v-card-title class="ma-0 pa-0" >
                       <v-icon color="green" class="ma-0 pa-0" large>mdi-menu-right</v-icon>
                        Amenities
          </v-card-title>
           <v-divider class="ma-0 mb-3"></v-divider>
      
        <v-row class="ma-3">
          <v-col class="ma-0 pa-0 mx-4" v-for="(item, i) in itemAmenitiesCategoria" :key="i" cols="12">            
                <div class="title px-4">{{item.titulo}}
                </div>      
                <div class="d-flex">
                      <v-avatar class="mx-5 mt-0 pa-2" tile>
                            <v-img :src="item.icono" contain=true></v-img>
                      </v-avatar>
                      <v-row dense>
                              <v-col dense class="caption ma-0 pa-0" cols="12"  v-for="(subitem,k) in filtroPorCategoria(item.id)" :key="`${k}-${subitem.idcategoria}`"
                                      v-html=subitem.titulo > 
                              </v-col>                              
                      </v-row>                            
                  </div>                
                    <v-container fluid>
                      <v-divider class="ma-0 mr-6"></v-divider>
                    </v-container>
          </v-col>
        </v-row>
 <v-card-title class="ma-0 pa-0" >
                       <v-icon color="green" class="ma-0 pa-0" large>mdi-menu-right</v-icon>
                        Actividades en el complejo
          </v-card-title>
           <v-divider class="ma-0 mb-3"></v-divider>
           
      <v-row class="ma-3">
          <v-col class="ma-0 pa-0 mx-4" v-for="(item, i) in itemActividadesCategoria" :key="i" cols="12">            
                <div class="title px-4">{{item.titulo}}
                </div>      
                <div class="d-flex">
                      <v-avatar class="mx-5 mt-0 pa-2" tile>
                            <v-img :src="item.icono" contain=true></v-img>
                      </v-avatar>
                      <v-row dense>
                              <v-col dense class="caption ma-0 pa-0" cols="12"  v-for="(subitem,k) in filtroPorCategoriaB(item.id)" :key="`${k}-${subitem.idcategoria}`"
                                      v-html=subitem.titulo > 
                              </v-col>                              
                      </v-row>                            
                  </div>                
                    <v-container fluid>
                      <v-divider class="ma-0 mr-6"></v-divider>
                    </v-container>
          </v-col>
        </v-row>
    </v-card>       
  </v-container>
</template>
<script>

export default {
  name:"serviciosrde",
  data() {
    return { 
        itemAmenitiesCategoria: [
                                  { id:'1',
                                    titulo:'',
                                    icono:require('../assets/ubicacion.gif')
                                  },
                                  { id:'2',
                                    titulo:'Apartamentos',
                                    icono:require('../assets/casa.gif')
                                  },
                                  { id:'3',
                                    titulo:'Room Service',
                                    icono:require('../assets/room.gif')
                                  },
                                  { id:'4',
                                    titulo:'Club House',
                                    icono:require('../assets/juegos.gif')
                                  },
                                   { id:'5',
                                    titulo:'Spa',
                                    icono:require('../assets/spa.gif')
                                  },
                                  { id:'6',
                                    titulo:'Piscinas',
                                    icono:require('../assets/piscina.gif')
                                  },
                                  { id:'7',
                                    titulo:'Rincón Infantil',
                                    icono:require('../assets/lapiz.gif')
                                  },
                                  { id:'8',
                                    titulo:'Golf',
                                    icono:require('../assets/golf.gif')
                                  },
                                  { id:'9',
                                    titulo:'Deportes',
                                    icono:require('../assets/tennis.gif')
                                  },
                                   { id:'10',
                                    titulo:'Excursiones',
                                    icono:require('../assets/cartel.gif')
                                  },
                                   {
                                   id:'11',
                                    titulo:'Gastronomias',
                                    icono:require('../assets/gourmet.gif')
                                  }
                        ],
        itemAmenities: [
                                  { idcategoria:'1',
                                    titulo:'Recepción las 24 horas'
                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Estacionamiento, vigilancia'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'86 confortables apartamentos'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Capacidad para 2, 3, 4, 6 y 8 personas'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Balcón y parrilla propia'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Totalmente equipados con vajilla'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Ropa blanca'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Wi-Fi'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Servicio de mucamas'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Lavandería'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Sala de Juegos'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Sala de video'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Sala de Estar y Reuniones'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Drugstore'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Sauna'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Masajes'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Gimnasio'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Vestuarios'                                    
                                  },
                                  { idcategoria:'6',
                                    titulo:'Abierta Climatizada'                                    
                                  },
                                  { idcategoria:'6',
                                    titulo:'Cubierta Climatizada con hidromasaje'                                    
                                  },
                                  { idcategoria:'6',
                                    titulo:'Solarium'                                    
                                  },
                                  { idcategoria:'7',
                                    titulo:'Sala de Juegos para los más chicos'                                    
                                  },
                                   { idcategoria:'8',
                                    titulo:'Putting Green de 9 hoyos'                                    
                                  },
                                   { idcategoria:'8',
                                    titulo:'Jaula de golf'                                    
                                  },
                                   { idcategoria:'8',
                                    titulo:'Pro Shop de golf'                                    
                                  },
                                   { idcategoria:'9',
                                    titulo:'Cancha de Tenis de polvo de ladrillo'                                    
                                  },
                                   { idcategoria:'9',
                                    titulo:'Canchas de paddle'                                    
                                  },
                                   { idcategoria:'9',
                                    titulo:'Cancha de voley'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Cabalgatas'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Agroturismo'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Travesías en bicicleta'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Turismo relax'                                    
                                  },
                                  { idcategoria:'10',
                                    titulo:'Actividades recreativas y turisticas<br>para toda la familia'                                    
                                  },
                                  { idcategoria:'11',
                                    titulo:'Restaurante RINCÓN GOURMET'                                    
                                  },
                                  { idcategoria:'11',
                                    titulo:'Comida internacional'                                    
                                  },
                                  { idcategoria:'11',
                                    titulo:'Barbacoa exterior'                                    
                                  }
                        ],
              itemActividadesCategoria: [
                                  { id:'1',
                                    titulo:'Deportes',
                                    icono:require('../assets/tennis.gif')
                                  },
                                  { id:'2',
                                    titulo:'Entretenimiento',
                                    icono:require('../assets/microfono.gif')
                                  },
                                  { id:'3',
                                    titulo:'Juegos',
                                    icono:require('../assets/juegos.gif')
                                  },
                                  { id:'4',
                                    titulo:'Rincón Infantil',
                                    icono:require('../assets/lapiz.gif')
                                  },
                                   { id:'5',
                                    titulo:'Clases: Gym / Baile ',
                                    icono:require('../assets/reloj.gif')
                                  }
                        ],
        itemActividades: [
                                  
                                  { idcategoria:'1',
                                    titulo:'Putting green'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Jaula de Golf'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Tenis'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Paddle'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Piscina cubierta climatizada'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Croquet'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Futbol'                                    
                                  },
                                  { idcategoria:'1',
                                    titulo:'Voley'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Copa de bienvenida'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Shows en vivo'                                    
                                  },
                                  { idcategoria:'2',
                                    titulo:'Karaoke'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Juegos de mesa'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Pool'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Ping Pong'                                    
                                  },
                                  { idcategoria:'3',
                                    titulo:'Bingo'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Sala de Juegos'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Manualidades'                                    
                                  },
                                  { idcategoria:'4',
                                    titulo:'Talleres para chicos'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Aerolatino'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Aerobox'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Gym Localizada'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Aeróbica'                                    
                                  },
                                  { idcategoria:'5',
                                    titulo:'Hidrogimnasia'                                    
                                  }
                        ]
    }
  },
  methods:{
            filtroPorCategoria(id){
                                   let resultado=[]
                                   resultado=this.itemAmenities
                                    resultado = resultado.filter(function(item) {
                                    return item.idcategoria === id
                                    })
                                   return resultado           
            },
            filtroPorCategoriaB(id){
                                   let resultado=[]
                                   resultado=this.itemActividades
                                    resultado = resultado.filter(function(item) {
                                    return item.idcategoria === id
                                    })
                                   return resultado           
            }
          }    
 }
</script>